<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <h2 class="text-lg font-bold">
        <img src="@/assets/images/office-staff.png" class="h-20 inline-block" />
        گروه ها
      </h2>
    </template>
    <template #default>
      <div class="bg-blueGray-100 border border-blueGray-200 p-3 rounded-md">
        <form @submit.prevent="submit">
          <div class="grid sm:grid-cols-3 gap-2">
            <MnrText v-model="search" placeholder="جستجو" class="sm:col-span-2" />
            <div>
              <Button type="submit">جستجو</Button>
            </div>
          </div>
        </form>
      </div>
      <div class="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-5">
        <div
          v-for="group in groups"
          :key="group.id"
          class="px-5 bg-blueGray-100 border-blueGray-200 border rounded-md"
        >
          <h2 class="text-lg font-bold mt-5 mb-3">{{ group.name }}</h2>
          <div class="grid grid-cols-2 p-1">
            <div>ID</div>
            <div class="text-left" dir="ltr">{{ group.gid }}</div>
          </div>
          <div class="grid grid-cols-2 p-1">
            <div>ممبر</div>
            <div class="text-left" dir="ltr">{{ group.members }}</div>
          </div>
          <div class="grid grid-cols-2 p-1">
            <div>ربات</div>
            <div class="text-left" dir="ltr">
              {{ list_pro[group.pro_type] ?? "رایگان" }}
            </div>
          </div>
          <div class="grid grid-cols-2 gap-2 mt-2 mb-2">
            <Button
              :to="{
                name: 'GroupOwnerSetting',
                params: { id: $store.state.user.id, groupId: group.id },
              }"
              class="text-sm h-10 lg:text-lg"
              color="sky"
            >
              تنظیمات
            </Button>
            <Button
              :to="{
                name: 'GroupOwnerBuy',
                params: { id: $store.state.user.id, groupId: group.id },
              }"
              class="text-sm h-10 lg:text-lg"
              color="green"
            >
              خرید ربات
            </Button>
          </div>
          <div class="grid grid-cols-2 gap-2 mb-4">
            <Button
              :to="{
                name: 'GroupUserInfo',
                params: { id: $store.state.user.id, groupId: group.id },
              }"
              class="text-sm h-10 lg:text-lg"
              color="sky"
            >
              بیشتر
            </Button>
            <Button
              title=""
              :to="{
                name: 'GroupUserAccounts',
                params: { id: $store.state.user.id, groupId: group.id },
              }"
              class="text-sm h-10 lg:text-lg"
              color="green"
            >
              خرید اشتراک
            </Button>
          </div>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import MnrText from "@/components/mnr/MnrText.vue";
import Button from "@/components/Button.vue";
export default {
  components: { BodySimple, MnrText, Button },
  data() {
    return {
      firstLoading: true,
      groups: [],
      search: "",
      list_pro: {
        green: "سبز",
        blue: "آبی",
        bronze: "برنزی",
        silver: "نقره‌ای",
        golden: "طلایی",
      },
    };
  },
  mounted() {
    let $this = this;

    this.$router
      .isReady()
      .then(function () {
        $this.search = $this.$route.query.search ?? $this.search;

        $this.submit();
      })
      .catch();
  },
  methods: {
    submit() {
      this.groups = [];
      this.$router.replace({
        query: {
          search: this.search,
        },
      });

      let $this = this;
      // console.log($this.$store.state.user.id);
      $this.$axios
        .get("/api/admin/groups", {
          params: {
            id: $this.$store.state.user.id,
            search: $this.search,
            // branch: $this.branch.value,
            // searchBy: $this.searchBy.value,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.groups = response.data.groups;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .finally(function () {
          // always executed
          $this.firstLoading = false;
        });
    },
  },
};
</script>
